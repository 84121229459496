import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { Menubar } from "primereact/menubar";
import { useLocation } from "react-router-dom";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { BreadCrumb } from "primereact/breadcrumb";
import "primeicons/primeicons.css";

const routerEvents = new Subject();

const MenuGeneralComponent = ({ keycloak, theme }) => {
  const [dialOpen, setDialOpen] = useState(false);
  const menu = useRef(null);
  const homeMenu = useRef(null);
  const [migas, setMigas] = useState([]);
  const location = useLocation();
  const [visibleMenuPortal] = useState(true);

  useEffect(() => {
    const obtenerMigas = () => {
      const segments = location.pathname
        .split("/")
        .filter((segment) => segment);
      const newMigas = segments.map((segment, index) => {
        const isFirst = index === 0;
        const path = `/${segments.slice(0, index + 1).join("/")}`;
        return {
          label: (
            <span className="mx-1">
              {isFirst && segment === "sge" ? (
                <i className="pi pi-home" />
              ) : (
                segment
              )}
            </span>
          ),
          url: path,
        };
      });
      setMigas(newMigas);
    };

    const subscription = routerEvents
      .pipe(filter((event: any) => event.event === "NavigationEnd"))
      .subscribe(obtenerMigas);

    obtenerMigas();

    return () => {
      subscription.unsubscribe();
    };
  }, [location]);

  const handleChangeTheme = () => {
    theme();
  };

  const menuModulo = [
    {
      label: "Inicio",
      command: () => {
        window.location.href = "/";
      },
    },
    { label: "Wiki" },
    { label: "Soporte" },
  ];

  const menuItems = [
    {
      label: "Contabilidad",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Facturacion",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Productos",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "R.H",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Pedidos",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Ventas",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Legal",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Veterinaria",
      command: () => {
        window.location.hash = "/";
      },
    },
  ];

  const menuConfiguracion = [
    {
      label: "Inicio",
      command: () => {
        window.location.href = "/sge/portal/";
      },
    },
    {
      label: "Soporte",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Wiki",
      command: () => {
        window.location.hash = "/portal/wiki";
      },
    },
    {
      label: "Perfil",
      command: () => {
        window.location.hash = "/";
      },
    },
    {
      label: "Theme",
      icon: "pi pi-palette",
      command: handleChangeTheme,
    },
    {
      label: "Salir",
      icon: "pi pi-power-off",
      command: () => {
        keycloak.logout();
      },
    },
  ];

  return (
    <>
      <header
        className="flex justify-content-between"
        style={{ height: "45px" }}
      >
        <div className="flex align-items-center">
          <Button
            className="mr-1"
            icon="pi pi-bars"
            onClick={(e) => menu.current.toggle(e)}
          />
          <TieredMenu model={menuModulo} popup ref={menu} breakpoint="767px" />
          <BreadCrumb model={migas} className="max-w-full p-2" />
        </div>
        <div className="flex align-items-center">
          {dialOpen && <Menubar model={menuConfiguracion} />}
          <Button
            className="ml-1"
            icon="pi pi-cog"
            onClick={() => setDialOpen(!dialOpen)}
          />
        </div>
      </header>

      {visibleMenuPortal && (
        <div>
          <Button
            icon="pi pi-home"
            style={{
              position: "fixed",
              bottom: "1%",
              left: "1%",
              zIndex: 10000,
            }}
            onClick={(e) => homeMenu.current.toggle(e)}
          />
          <TieredMenu model={menuItems} popup ref={homeMenu} />
        </div>
      )}
    </>
  );
};

export default MenuGeneralComponent;
